@import "sass/variables";

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* styles for first page */

* {
    padding: 0;
    margin: 0;
}

body {
    font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 13px;
    line-height: 1.846;
}

img {
    margin: 15px 0 0 27px;
    height: 69.5px;
}

.formContainer,
.paymentContainer,
.ImageUpload {
    max-width: 751px;
    width: 100%;
    box-shadow: 0 5px 15px #00000096;
    margin: 0 auto;
    top: 321px;
    left: 0;
    right: 0;
    border-radius: 14px;
    border: 1px solid transparent;
    display: grid;
    padding: 15px;
    align-items: inherit;
}

.formContainer {
    min-height: 166px;
}

.paymentContainer {
    min-height: 200px;
}

/* "You owe nothing at this time" */
.nothingOwed {
    text-align: center;
    align-self: center;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    /* styling for input fields */
    input {
        border: none;
        border-bottom: 1px solid grey;
        color: grey;
        font-size: 16px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
            "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
            "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        width: 100%;
        height: 37px;
        margin: 5.5px 0;

        /* Change style of placeholder text for input tags */
        &::placeholder {
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
                "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
                "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            text-align: left;
            color: #c0c0c0;
        }
    }
}

.submitButton {
    color: white;
    background-color: $green;
    border: none;
    border-radius: 3px;
    padding: 6px 16px;
    font-weight: 300;
    width: 100%;
    height: 36px;
    margin-top: 11px;
    cursor: pointer;
}

.errorText {
    color: $red;
    text-align: left;
}

.errorBorder {
    border: 1px solid $red;
    border-radius: 4px;
}

span {
    text-align: center;
}

/* remove underline and text color from a tags */
a {
    text-decoration: none;
    color: #000000;
}

/* a tags on hover */
a:hover {
    cursor: pointer;
    text-decoration: underline;
}

// home button in upper right hand corner
.homeButton {
    position: absolute;
    top: 15px;
    right: 27px;
    height: 3em;
    color: #f4311f;
    cursor: pointer;
}

/* hide up and down arrows for input tags with number type */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* styles for firefox */
@-moz-document url-prefix() {
    .submitButton {
        font-weight: 400;
    }

    /* Change style of placeholder text for input tags */
    form > input::placeholder {
        color: hsl(0, 0%, 45%);
    }
}

/* styles for safari */
@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
        .submitButton {
            font-weight: 400;
            font-size: 13px;
        }
    }
}

/* styles for mobile */
@media only screen and (max-width: 850px) {
    .formContainer,
    .paymentContainer {
        margin: 0 30px;
        position: static;
        flex-basis: 100%;
    }

    .Header {
        margin-bottom: 30px;
    }

    .Lookup,
    .Payment,
    .Portal {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 90vh;
    }

    /* "You owe nothing at this time" */
    .nothingOwed {
        font-size: 25px;
    }
}

/* Stop form from touching bottom of screen */
.Lookup,
.Portal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 81vh;
  }
  
.thank-you {
    text-align: center;
    display: block;
    // height: 80vh;
    height: calc(100vh - 145px);
}
.thank-you h1 {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

//modal
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.modal-content {
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    position: relative;
}

.close-modal-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    padding: 0.5rem;
    border-radius: 50%;
    cursor: pointer;
}